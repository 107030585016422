import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/operations')
const postAxios = data => axiosWarehouse.post('/saleInvoices', data)
const oneAxios = id => axiosWarehouse.get(`/operations/${id}`)
const searchAxios = id => axiosWarehouse.get(`/operations/search/${id}`)
const lastIdAxios = id => axiosWarehouse.get(`/operations/last/${id}`)
const putAxios = (id, data) => axiosWarehouse.put(`/operations/${id}`, data)
const deleteAxios = id => axiosWarehouse.delete(`/operations/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
  searchAxios,
  lastIdAxios,
}
