<template>
  <b-sidebar
    id="invoice-sidebar-item-serial"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Item Serial
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-overlay
          :show="showOverLay"
          rounded="sm"
          :opacity="0.5"
        >
          <b-form
            ref="formName"
            class="p-2"
            @submit.prevent
          >
            <b-form-group
              label="Item Name"
              label-for="itemName"
            >
              <v-select
                v-model="item_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="item_option"
                :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                placeholder="Select Item Name"
                input-id="item"
                :clearable="false"
              />
            </b-form-group>
            <b-form-group
              label="Serial Number"
              label-for="serialNumber"
            >
              <validation-provider
                #default="{ errors }"
                name="Serial Number"
                rules="required"
              >
                <b-form-input
                  id="serial-number"
                  v-model="serial_number"
                  placeholder="Serial Number"
                  trim
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="validationForm"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
          <b-card>
            <b-table-lite
              responsive
              :items="rows"
              :fields="['item', 'serial_number']"
            >
              <template #cell(item)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ $store.state.appConfig.layout.isRTL ? data.item.item.name_ar : data.item.item.name_en }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card>
        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BOverlay, BTableLite,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import item from '@/service/warehouse/item'
import itemSerial from '@/service/warehouse/item-serial'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BTableLite,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      required,
      serial_number: '',
      item_id: '',
      item_option: [],
      rows: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
    }
  },
  created() {
    this.getApiSelect()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postApi()
        }
      })
    },
    async postApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await itemSerial.postAxios({
        item_id: this.item_id.id,
        serial_number: this.serial_number,
        operation_id: this.id,
        user_id: user.id,
      }).then(() => {
        this.showError = false
        this.showToast('Notification', 'CheckCircleIcon', 'Item Serial Number saved successfully.👍', 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showOverLay = false
        this.showToast('Error', 'XCircleIcon', 'Item Serial Number  Not saved', 'danger')
        return error
      })
      this.reloadTable()
      this.showOverLay = false
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async getApiSelect() {
      this.showOverLay = true
      await item.getAxios().then(response => {
        this.item_option = response.data
        // eslint-disable-next-line prefer-destructuring
        this.item_id = this.item_option[0]
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      this.reloadTable()
      this.showOverLay = false
    },
    async reloadTable() {
      await itemSerial.searchAxios(this.id).then(response => {
        this.rows = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
