import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/itemSerials')
const postAxios = data => axiosWarehouse.post('/itemSerials', data)
const oneAxios = id => axiosWarehouse.get(`/itemSerials/${id}`)
const searchAxios = id => axiosWarehouse.get(`/itemSerials/search/${id}`)
const putAxios = (id, data) => axiosWarehouse.put(`/itemSerials/${id}`, data)
const deleteAxios = id => axiosWarehouse.delete(`/itemSerials/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  searchAxios,
  putAxios,
  deleteAxios,
}
