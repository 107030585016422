import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/logictedOperations')
const postAxios = data => axiosWarehouse.post('/logictedOperations', data)
const oneAxios = id => axiosWarehouse.get(`/logictedOperations/${id}`)
const searchAxios = id => axiosWarehouse.get(`/logictedOperations/search/${id}`)
const putAxios = (id, data) => axiosWarehouse.put(`/logictedOperations/${id}`, data)
const deleteAxios = id => axiosWarehouse.delete(`/logictedOperations/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  searchAxios,
  putAxios,
  deleteAxios,
}
