import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/paymentOperations')
const postAxios = data => axiosWarehouse.post('/paymentOperations', data)
const oneAxios = id => axiosWarehouse.get(`/paymentOperations/${id}`)
const searchAxios = id => axiosWarehouse.get(`/paymentOperations/search/${id}`)
const putAxios = (id, data) => axiosWarehouse.put(`/paymentOperations/${id}`, data)
const deleteAxios = id => axiosWarehouse.delete(`/paymentOperations/${id}`)
const searchByDocument = (documentTypeId, documentNo) => axiosWarehouse.get(`/paymentOperations/searchByDocument/${documentTypeId}/${documentNo}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  searchAxios,
  putAxios,
  deleteAxios,
  searchByDocument,
}
