import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/payments')
const postAxios = data => axiosIns.post('/payments', data)
const oneAxios = id => axiosIns.get(`/payments/${id}`)
const putAxios = (id, data) => axiosIns.put(`/payments/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/payments/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
