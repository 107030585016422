import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/bankBranches')
const postAxios = data => axiosIns.post('/bankBranches', data)
const oneAxios = id => axiosIns.get(`/bankBranches/${id}`)
const putAxios = (id, data) => axiosIns.put(`/bankBranches/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/bankBranches/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
