import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/warehousePartItems')
const postAxios = data => axiosWarehouse.post('/warehousePartItems', data)
const oneAxios = id => axiosWarehouse.get(`/warehousePartItems/${id}`)
const searchAxios = id => axiosWarehouse.get(`/warehousePartItems/search/${id}`)
const putAxios = (id, data) => axiosWarehouse.put(`/warehousePartItems/${id}`, data)
const deleteAxios = id => axiosWarehouse.delete(`/warehousePartItems/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  searchAxios,
  putAxios,
  deleteAxios,
}
